import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import ServiceDetails from "@/components/service-details";
import CallToActionOne from "@/components/call-to-action-one";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
//import { ServiceDetailsData } from "@/data";

import { useQueryParam } from "gatsby-query-params";

const ServiceDetailsPage = () => {
  const page = useQueryParam("page");
//  const data = ServiceDetailsData[page]?ServiceDetailsData[page]:null;

//  console.info(page, data);

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Service">
          <HeaderOne />
          <PageBanner title="Service" name="Service" page='service'/>
          <ServiceDetails page={page}/>
          <CallToActionOne extraClassName="ready" />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default ServiceDetailsPage;
